import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { isProductionOrStaging } from '../../utils/environment';
import originCheck from '../../utils/origin-check';
import { getCookie } from '../../utils/cookies';
import normalizeNationalId from '../../utils/normalize-national-id';

const initialBaseUrl = process.env.REACT_APP_API_ROOT_PAPI || process.env.REACT_APP_API_ROOT;
const newBaseUrl = process.env.REACT_APP_API_ROOT_ONBOARDING;

const dynamicBaseQuery = async (args, apiCall, extraOptions) => {
  const baseUrl = args.url.includes('create/') || args.url.includes('createMortgage/') || args.url.includes('bloomreach/') ? newBaseUrl : initialBaseUrl;
  const rawBaseQuery = fetchBaseQuery({ baseUrl });
  return rawBaseQuery(args, apiCall, extraOptions);
};

const SentryErrors = (props) => {
  const [response, meta] = props;
  const errorMessage = typeof response.data === 'object' ? response.data.message : response.data;
  const request = meta.request.body.getReader().read().then((result) => {
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(result.value);
  });
  return request
    .then((requestData) => (
      { errorMessage, response, payload: JSON.parse(requestData) }));
};

export const apiCall = createApi({
  reducerPath: 'apiCallReducer',
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getApplication: builder.query({
      query: (args) => {
        const url = window.location.href;
        const siteDomain = originCheck(url);

        const {
          t, src, isCAPrefillToken, communicationChannel, region,
        } = args;
        return {
          url: '/contacts/get-latest-application',
          params: {
            t, src, siteOrigin: siteDomain, isCAPrefillToken, communicationChannel, region,
          },
        };
      },
      transformErrorResponse: (...props) => {
        const [response] = props;
        const errorMessage = typeof response.data === 'object' ? response.data.message : response.data;
        return { errorMessage, response };
      },
    }),
    onSubmitApplication: builder.mutation({
      query: (args) => {
        const url = window.location.href;
        const siteDomain = originCheck(url);

        const [application, queryData] = args;
        const { query, rawQuery } = queryData;

        return {
          url: `/applications/create/${query ? `?${query}` : ''}`,
          method: 'post',
          credentials: isProductionOrStaging() ? 'include' : 'omit',
          body: {
            ...application,
            siteOrigin: siteDomain,
            page: window.location.pathname,
            externalTrackingId:
              rawQuery && rawQuery.tracking_id ? rawQuery.tracking_id : null,
            // Seems to be needed locally for NO to be able to submit
            // creditCheckConsent: null,
          },
        };
      },
      transformErrorResponse: (...props) => SentryErrors(props),
    }),
    onSubmitMortgageApplication: builder.mutation({
      query: (args) => {
        const url = window.location.href;
        const siteDomain = originCheck(url);
        const [application, queryData] = args;
        const { query } = queryData;

        return {
          url: `/applications/createMortgage/${query ? `?${query}` : ''}`,
          method: 'post',
          credentials: isProductionOrStaging() ? 'include' : 'omit',
          body: {
            ...application,
            siteOrigin: siteDomain,
          },
        };
      },
    }),
    submitCartAbandonment: builder.mutation({
      query: (args) => {
        const url = window.location.href;
        const siteDomain = originCheck(url);
        const internalCookie = getCookie('external_id');

        return {
          url: '/contacts/create-cart-abandonment-contact',
          method: 'post',
          body: {
            phoneNumber: args.applicant.phoneNumber,
            email: args.applicant.email,
            totalAmount: args.totalAmount,
            repaymentTime: args.repaymentTime,
            mergeLoan: args.merge,
            page: window.location.pathname,
            siteOrigin: siteDomain,
            queryParams: args.queryParams,
            acceptNewsletter: args.acceptNewsletter,
            bloomreachCookie: getCookie('__exponea_etc__'),
            internalCookie,
          },
        };
      },
      transformErrorResponse: (...props) => SentryErrors(props),
    }),
    submitMortgageCartAbandonment: builder.mutation({
      query: (args) => {
        const url = window.location.href;
        const siteDomain = originCheck(url);

        return {
          url: '/contacts/create-mortgage-cart-abandonment-contact',
          method: 'post',
          body: {
            purpose: args.purpose,
            email: args.applicant.email,
            phoneNumber: args.applicant.phoneNumber,
            acceptNewsletter: args.acceptNewsletter,
            page: window.location.pathname,
            siteOrigin: siteDomain,
          },
        };
      },
    }),
    submitContactForm: builder.mutation({
      query: (args) => {
        const url = window.location.href;
        const siteDomain = originCheck(url);

        const {
          message, name, email, phone: phonenumber, toEmail, toName,
        } = args;

        return {
          url: '/contact/form',
          method: 'post',
          body: {
            message,
            name,
            email,
            phoneNumber: phonenumber,
            siteOrigin: siteDomain,
            toEmail: toEmail || null,
            toName: toName || null,
          },
        };
      },
      transformErrorResponse: (...props) => SentryErrors(props),
    }),
    unsubscribeFormSms: builder.mutation({
      query: (args) => {
        const url = window.location.href;
        const siteDomain = originCheck(url);

        const { sms, cID } = args;

        return {
          url: '/contacts/unsubscribe-phone',
          method: 'post',
          body: {
            mobilePhone: sms,
            campaignId: cID,
            siteOrigin: siteDomain,
          },
        };
      },
    }),
    unsubscribeFormEmail: builder.mutation({
      query: (args) => {
        const url = window.location.href;
        const siteDomain = originCheck(url);

        const { email, cID } = args;

        return {
          url: '/contacts/unsubscribe-email',
          method: 'post',
          body: {
            email,
            campaignId: cID,
            siteOrigin: siteDomain,
          },
        };
      },
    }),
    unsubscribeFormPost: builder.mutation({
      query: (args) => {
        const url = window.location.href;
        const siteDomain = originCheck(url);
        const { cID } = args;
        const nationalId = process.env.REACT_APP_REGION === 'SE'
          ? normalizeNationalId(args.nationalId) : args.nationalId;

        return {
          url: '/contacts/unsubscribe-post',
          method: 'post',
          body: {
            nationalId,
            campaignId: cID,
            siteOrigin: siteDomain,
          },
        };
      },
    }),

    emailSubmission: builder.mutation({
      query: (args) => {
        const { email, href } = args;
        const url = '/bloomreach/email/submit';
        return {
          url,
          method: 'post',
          body: {
            email,
            href,
          },
        };
      },
      transformErrorResponse: (...props) => SentryErrors(props),
    }),
  }),
});

export const {
  useGetApplicationQuery,
  useOnSubmitApplicationMutation,
  useOnSubmitMortgageApplicationMutation,
  useSubmitCartAbandonmentMutation,
  useSubmitMortgageCartAbandonmentMutation,
  useSubmitContactFormMutation,
  useUnsubscribeFormSmsMutation,
  useUnsubscribeFormEmailMutation,
  useUnsubscribeFormPostMutation,
  useEmailSubmissionMutation,
} = apiCall;
