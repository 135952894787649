import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { change } from 'redux-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import devices from '../../../styles/Devices'; // eslint-disable-line import/no-relative-packages
import ValidatedForm from '../../../../components/molecules/ValidatedForm';
import { setContextValue } from '../../../store/actions/api/set-context-value';
import intlShape from '../../../schemas/intl';
import style from './style';

const GridListOuterWrapper = styled.div`
    && {
      hr {
        width: 100%;
        margin-bottom: 2rem;
        border: 0.5px solid black;
      }
    }
`;

const GridListInnerWrapper = styled.div`
    && {
      display: flex;
      justify-content: space-between;
      align-items:center;

      @media ${devices.downFromSmallTablet} {
        flex-direction: column;
      }
    }
`;
const RemoveButton = styled.div`
    && {
        font-weight: bold;
        padding-bottom: 1rem;
        cursor: pointer;
        align-self: normal;
    }
`;
const RemoveIcon = styled.svg`
    && {
      cursor: pointer;
      min-width: 28px;
      align-self: baseline;
      margin-left: 1rem;
    }
`;
const AddButton = styled.div`
    && {
        text-align: center;
        background: ${style.addButtonBg};
        border: 1px solid var(--base-brown);
        padding: 0.8rem;
        cursor: pointer;
        margin-bottom: 1rem;
    }
`;
const GridListRow = styled.div`
    && {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${style.gridGap};

        @media ${devices.downFromSmallTablet} {
          width: 100%;
          grid-template-columns: repeat(1, 1fr);
        }
    }
`;

const ExpandingGridList = ({
  data,
  showErrors,
  intl: { formatMessage },
}) => {
  // Store dispatch
  const dispatch = useDispatch();
  const updateValue = (...args) => dispatch(setContextValue(...args));
  const changeField = (...args) => change('application', ...args);

  // Store values
  const formState = useSelector((state) => state.form.application);
  const storeApplicant = formState.values[data.sectionName];
  const storeVal = storeApplicant[data.name];
  const initialNumberOfRows = (storeVal?.length || 0);

  const [numberOfRows, setNumberOfRows] = useState(initialNumberOfRows);
  const [rowArr, setRowArr] = useState([]);

  const buildRows = () => {
    const newRows = [];

    for (let i = 0; i < numberOfRows; i += 1) {
      newRows.push(data.columns);
    }

    setRowArr(newRows);
  };

  const addRowsHandler = () => {
    setNumberOfRows(numberOfRows + 1);
    buildRows();
  };

  const removeRowsHandler = (index) => {
    rowArr.splice(index, 1);
    storeVal.splice(index, 1);

    setRowArr(rowArr);
    setNumberOfRows(numberOfRows - 1);

    dispatch(updateValue({ context: data.name, value: storeVal }));
    dispatch(changeField(data.name, storeVal));
  };

  const buildStorePath = (col, key) => (`${data.sectionName}.${data.name}[${key}]${col.name}`);

  const dynamicVisibility = (col, key) => {
    const currentStoreObject = storeVal[key];
    if (col.internalVisibleWhen) {
      let matchFound = false;

      if (
        currentStoreObject
        && col.internalVisibleWhen.values.includes(currentStoreObject[col.internalVisibleWhen.key])
      ) {
        matchFound = true;
      }

      if (storeVal) {
        if (!matchFound && storeVal[key] && storeVal[key][col.name] !== null) {
          storeVal[key][col.name] = null;
          dispatch(updateValue({ context: data.name, value: storeVal }));
        } else if (matchFound && storeVal[key] && storeVal[key][col.name] === null) {
          storeVal[key][col.name] = col.internalVisibleWhen.initialValue;
          dispatch(updateValue({ context: data.name, value: storeVal }));
        }
      }

      return matchFound;
    }
    return true;
  };

  useEffect(() => {
    buildRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfRows]);

  return (
    <GridListOuterWrapper>
      <>
        {rowArr.map((row, key) => (
          <>
            <GridListInnerWrapper key={key}>
              <GridListRow>
                {row.map((col, colKey) => (
                  <>
                    {col.component === 'Select' && (dynamicVisibility(col, key)) && (
                      <ValidatedForm.Select
                        key={colKey}
                        showErrors={showErrors}
                        data={{
                          name: buildStorePath(col, key),
                          placeholder: col.placeholder,
                          options: col.options,
                          label: col.label,
                          tooltip: col.tooltip,
                          validate: col.validate,
                          hasValidateFunction: col.hasValidateFunction,
                        }}
                      />
                    )}
                    {col.component === 'TextInput' && (dynamicVisibility(col, key)) && (
                      <ValidatedForm.TextInput
                        key={colKey}
                        showErrors={showErrors}
                        data={{
                          name: buildStorePath(col, key),
                          type: col.type,
                          isNumber: col.isNumber,
                          placeholder: col.placeholder,
                          label: col.label,
                          tooltip: col.tooltip,
                          validate: col.validate,
                          hasValidateFunction: col.hasValidateFunction,
                        }}
                      />
                    )}
                    {col.component === 'Checkbox' && (dynamicVisibility(col, key)) && (
                      <ValidatedForm.Checkbox
                        key={colKey}
                        showErrors={showErrors}
                        data={{
                          name: buildStorePath(col, key),
                          label: col.label,
                          tooltip: col.tooltip,
                          validate: col.validate,
                          hasValidateFunction: col.hasValidateFunction,
                        }}
                      />
                    )}
                  </>
                ))}
              </GridListRow>

              {data.removeButtonLabel ? (
                <RemoveButton onClick={() => removeRowsHandler(key)}>
                  {formatMessage(data.removeButtonLabel)}
                </RemoveButton>
              ) : (
                <RemoveIcon onClick={() => removeRowsHandler(key)} width="28" height="28" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="delete">
                    <path id="delete_2" d="M3.26869 7.87635C3.09357 7.87635 2.94365 7.81399 2.81894 7.68928C2.69423 7.56457 2.63188 7.41465 2.63188 7.23953V2.9941H2.20734V2.35728H3.90551V1.93274H5.60368V2.35728H7.30186V2.9941H6.87731V7.23569C6.87731 7.41514 6.81496 7.56678 6.69025 7.69061C6.56554 7.81443 6.41562 7.87635 6.2405 7.87635H3.26869ZM6.2405 2.9941H3.26869V7.23953H6.2405V2.9941ZM3.90551 6.60272H4.54232V3.63091H3.90551V6.60272ZM4.96687 6.60272H5.60368V3.63091H4.96687V6.60272Z" fill="#1C1B1F" />
                  </g>
                </RemoveIcon>
              )}
            </GridListInnerWrapper>
            {rowArr.length - 1 !== key && (
              <hr />
            )}
          </>
        ))}
      </>
      <AddButton onClick={addRowsHandler}>{formatMessage(data.addButtonLabel)}</AddButton>
    </GridListOuterWrapper>
  );
};

ExpandingGridList.propTypes = {
  intl: intlShape.isRequired,
  data: PropTypes.shape().isRequired,
  showErrors: PropTypes.bool.isRequired,
};

export default injectIntl(ExpandingGridList);
